<script setup lang="ts">
const { t } = useT();

const props = withDefaults(
	defineProps<{
		value?: number;
		maxValue?: number;
		hideProgressBar?: boolean;
	}>(),
	{
		value: 0,
		maxValue: 100
	}
);
const pointsLeft = computed(() => props.maxValue - props.value);

const progressValue = computed(() => {
	if (pointsLeft.value === 0) {
		return "100%";
	}
	if (props.value !== 0) {
		return `${(props.value * 100) / props.maxValue}%`;
	}
	return "26px";
});
</script>

<template>
	<div class="progress-bar">
		<template v-if="pointsLeft > 0">
			<slot />
			<MPrizeFund variant="entries" img="/nuxt-img/prizes/citycoins@2x.png" :icon-size="18">
				<AText :size="16" class="text-tlalnepantla text-shadow text-cannes" :modifiers="['bold']">
					{{ `${numberFormat(value)}/${numberFormat(maxValue)}` }}
				</AText>
				<AText class="text-cannes" :modifiers="['semibold']">{{ t("scratchCards.progressBar.text") }}</AText>
			</MPrizeFund>
		</template>
		<AText
			v-else
			:size="16"
			class="text-tlalnepantla text-coro text-shadow"
			gradient="var(--goeteborg)"
			:modifiers="['uppercase', 'extrabold']"
		>
			<span>{{ t("scratchCards.progressBar.text2") }}</span>
		</AText>
		<div v-if="!hideProgressBar" class="progress-bar-line">
			<div class="progress-bar-thumb" :style="{ width: progressValue }" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.progress-bar {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	gap: 4px;
	min-height: 50px;

	&:deep(.prize) {
		gap: 4px;
	}

	&-line {
		width: 100%;
		height: 8px;
		border-radius: 50px;
		border: 1px solid var(--chulucanas);
		background: var(--chifeng);
		box-shadow: 0 -4px 0 0 var(--compton) inset;
		position: relative;
		padding: 2px;
	}

	&-thumb {
		transition: 0.3s;
		height: 100%;
		min-width: 12px;
		background: var(--goeteborg);
		box-shadow: inset 0 3px 0 var(--celle);
		border-radius: 10px;
	}
}
</style>
